
const v = require('./VERSION');
const local_dev = window.location.hostname === 'localhost' //'127.0.0.1';

const config = {
    version: `itrck ${v.VERSION}`,
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Lato', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'nl', // 'en' - English, 
    availableLanguages: ['nl', 'en', 'de', 'fr', 'it'],
    rtlLayout: false,
    env: process.env.REACT_APP_ENV,
    
    server: local_dev ? "http://localhost:8081" : process.env.REACT_APP_API_SERVER,
    rt_server: local_dev ? "http://localhost:8083" : process.env.REACT_APP_RT_API_SERVER,
    auth_server: local_dev ? "http://localhost:8081" : process.env.REACT_APP_AUTH_SERVER,

    colors: {
        visitorsIn: '#00ab9c',
        visitorsOut: '#086d8c',
        alert: '#dc3540',
        selfcheckCheckin: '#7b95b0',
        selfcheckCheckout: '#0dcaf0',
        selfcheckLogon: "#520dc2",
        selfcheckRenewal: "#ffcd39",
        selfcheckPayment: "#de5c9d",
    },
    endpoint: {
        rt: process.env.REACT_APP_ENDPOINT_RT,
        auth: process.env.REACT_APP_ENDPOINT_AUTH,
        admin: process.env.REACT_APP_ENDPOINT_ADMIN,
        report: process.env.REACT_APP_ENDPOINT_REPORT
    },
    firebase: {
        apiKey: process.env.REACT_APP_FB_APIKEY,
        authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
        databaseURL: process.env.REACT_APP_FB_DATABASEURL,
        projectId: process.env.REACT_APP_FB_PROJECTID,
        storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FB_APPID
    },
};

// console.debug(config);
console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') console.log = function () { };

export default config;
