// OAuthCallback.jsx
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const session_state = params.get('session_state');

      if (!code) {
        navigate('/login?error=no_code');
        return;
      }
        
      try {
        await login({code, session_state})

        // Redirect to dashboard or home
        navigate('/dashboard');
      } catch (error) {
        console.error('Auth error:', error);
        navigate('/login?error=auth_failed');
      }
    };

    handleCallback();
  }, [navigate]);

  return <div>Processing login...</div>;
};

export default OAuthCallback;